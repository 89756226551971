import { ReactNode, useMemo } from 'react';

import { AnyObject } from '@helpers/types';

import { ActivityAdditionalFields } from '@filters/additionalFields/ActivityAdditionalFields';
import { CompanyAdditionalFields } from '@filters/additionalFields/CompanyAdditionalFields';
import { ContactAdditionalFields } from '@filters/additionalFields/ContactAdditionalFields';
import { EmployeeAditionalFields } from '@filters/additionalFields/EnployeeAdditionalFields';
import { LeadAdditionalFields } from '@filters/additionalFields/LeadAdditionalFields';
import { TransferOrderAdditionalFields } from '@filters/additionalFields/TransferOrderAdditionalFields';
import {
  getActivityFilters,
  getAppraisalFilters,
  getCampaignFilters,
  getCompanyFilters,
  getContactFilters,
  getContractFilters,
  getContractFormsFilters,
  getDealTransactionsFilters,
  getDealsFilters,
  getEmployeeFilters,
  getEstimateFilter,
  getInventoryAdjustmentFilters,
  getInventoryAuditDetailsFilters,
  getInventoryAuditFilters,
  getInventoryInvoiceFilter,
  getLeadFilters,
  getMaintenanceFilters,
  getMarketingReportFilters,
  getMemoFilters,
  getMemoOutFilters,
  getOrdersFilters,
  getOrdersManagementFilters,
  getProductInventoryFilters,
  getProductInventoryItemFilters,
  getProductsFilters,
  getPurchaseRequestFilter,
  getReturnFilter,
  getSalesItemFilters,
  getTasksFilters,
  getTaxesFilters,
  getTransferOrdersFilters,
} from '@filters/filterFactories/additionalFilters';
import { AdditionalFilterFunction, FilterFieldType } from '@filters/types';

import { DealTransactionsAdditionalFields } from '@filters/additionalFields/DealTransactionsAdditionalFields';
import { InventoryAdjustmentAdditionalFields } from '@filters/additionalFields/InventoryAdjusmentAdditionFilters';
import { InventoryAuditAdditionalFields } from '@filters/additionalFields/InventoryAuditAdditionalFields';
import { InventoryAuditDetailsAdditionalFields } from '@filters/additionalFields/InventoryAuditDetailsAdditionalFields';
import { MaintenanceAdditionalFields } from '@filters/additionalFields/MaintenanceAdditionalFields';

import { AppraisalAdditionalFields } from '@filters/additionalFields/AppdaisalAdditionalFields';
import { CampaignAdditionalFields } from '@filters/additionalFields/CampaignAdditionalFields';
import { ContractFormsAdditionalFields } from '@filters/additionalFields/ContractFormsAdditionalFields';
import { ContractsAdditionalFields } from '@filters/additionalFields/ContractsAdditionalFields';
import { InventoryInvoiceAdditionalFields } from '@filters/additionalFields/InventoryInvoiceAdditionalFields';
import { InventoryItemReportAdditionalFields } from '@filters/additionalFields/InventoryItemReportAdditionalFields';
import { MarketingReportAdditionalFields } from '@filters/additionalFields/MarketingReportAdditionalFields';
import { MemoAdditionalFields } from '@filters/additionalFields/MemoAdditionalFields';
import { MemoOutAdditionalFields } from '@filters/additionalFields/MemoOutAdditionalFields';
import { ProductsAdditionalFields } from '@filters/additionalFields/ProductsAdditionalFields';
import { PurchaseRequestAdditionalFields } from '@filters/additionalFields/PurchaseRequestAdditionalFilters';
import { ReturnsAdditionalFields } from '@filters/additionalFields/ReturnsAdditionalFields';
import { SalesItemAdditionalFields } from '@filters/additionalFields/SalesItemAdditionalFields';
import { TaxesAdditionalFields } from '@filters/additionalFields/TaxesAdditionalFields';
import { DealsAdditionalFields } from '../additionalFields/DealsAdditionalFields';
import { EstimatesAdditionalFields } from '../additionalFields/EstimatesAdditionalFields';
import { InventoryProductsAdditionalFields } from '../additionalFields/InventoryProductsAdditionalFields';
import { OrdersAdditionalFields } from '../additionalFields/OrderAdditionalFields';
import { OrdersManagementAdditionalFields } from '../additionalFields/OrdersManagementAdditionalFields';
import { TasksAdditionalFields } from '../additionalFields/TasksAdditionalFields';

interface Props<T extends AnyObject = AnyObject> {
  entityName?: string;
  values?: Maybe<FilterFieldType & T>;
}

interface ReturnType<T extends AnyObject = AnyObject> {
  additionalFields?: ReactNode;
  additionalFilterFunction?: AdditionalFilterFunction<T>;
}

export const useAdditionalFilters = <T extends AnyObject = AnyObject>({
  entityName,
  values,
}: Props<T>): ReturnType => {
  return useMemo(() => {
    switch (entityName) {
      case 'leads':
        return {
          additionalFields: <LeadAdditionalFields values={values} />,
          additionalFilterFunction: getLeadFilters,
        };
      case 'contacts':
      case 'contacts-reports':
        return {
          additionalFields: (
            <ContactAdditionalFields values={values} entityName={entityName} />
          ),
          additionalFilterFunction: getContactFilters,
        };
      case 'companies':
        return {
          additionalFields: <CompanyAdditionalFields values={values} />,
          additionalFilterFunction: getCompanyFilters,
        };
      case 'activity':
        return {
          additionalFields: <ActivityAdditionalFields values={values} />,
          additionalFilterFunction: getActivityFilters,
        };
      case 'employee':
        return {
          additionalFields: <EmployeeAditionalFields values={values} />,
          additionalFilterFunction: getEmployeeFilters,
        };
      case 'tasks':
        return {
          additionalFields: <TasksAdditionalFields values={values} />,
          additionalFilterFunction: getTasksFilters,
        };
      case 'orders':
        return {
          additionalFields: (
            <OrdersAdditionalFields values={values} entityName={entityName} />
          ),
          additionalFilterFunction: getOrdersFilters,
        };
      case 'orders-management':
        return {
          additionalFields: (
            <OrdersManagementAdditionalFields
              values={values}
              entityName={entityName}
            />
          ),
          additionalFilterFunction: getOrdersManagementFilters,
        };
      case 'sales-item':
        return {
          additionalFields: (
            <SalesItemAdditionalFields
              values={values}
              entityName={entityName}
            />
          ),
          additionalFilterFunction: getSalesItemFilters,
        };
      case 'accounting-dealTransactions':
        return {
          additionalFields: (
            <DealTransactionsAdditionalFields
              values={values}
              enitityName={entityName}
            />
          ),
          additionalFilterFunction: getDealTransactionsFilters,
        };
      case 'transferOrders':
        return {
          additionalFields: <TransferOrderAdditionalFields values={values} />,
          additionalFilterFunction: getTransferOrdersFilters,
        };
      case 'inventoryAdjustment':
        return {
          additionalFields: (
            <InventoryAdjustmentAdditionalFields values={values} />
          ),
          additionalFilterFunction: getInventoryAdjustmentFilters,
        };
      case 'inventoryAudit':
        return {
          additionalFields: <InventoryAuditAdditionalFields values={values} />,
          additionalFilterFunction: getInventoryAuditFilters,
        };
      case 'inventoryAuditDetails':
        return {
          additionalFields: (
            <InventoryAuditDetailsAdditionalFields values={values} />
          ),
          additionalFilterFunction: getInventoryAuditDetailsFilters,
        };
      case 'deals-profile':
      case 'deals-management':
        return {
          additionalFields: (
            <DealsAdditionalFields values={values} entityName={entityName} />
          ),
          additionalFilterFunction: getDealsFilters,
        };
      case 'maintenance':
        return {
          additionalFields: <MaintenanceAdditionalFields values={values} />,
          additionalFilterFunction: getMaintenanceFilters,
        };
      case 'campaign':
        return {
          additionalFields: <CampaignAdditionalFields values={values} />,
          additionalFilterFunction: getCampaignFilters,
        };
      case 'return':
        return {
          additionalFields: <ReturnsAdditionalFields values={values} />,
          additionalFilterFunction: getReturnFilter,
        };
      case 'invoice':
        return {
          additionalFields: (
            <InventoryInvoiceAdditionalFields values={values} />
          ),
          additionalFilterFunction: getInventoryInvoiceFilter,
        };
      case 'contracts':
        return {
          additionalFields: <ContractsAdditionalFields values={values} />,
          additionalFilterFunction: getContractFilters,
        };
      case 'appraisal':
        return {
          additionalFields: <AppraisalAdditionalFields values={values} />,
          additionalFilterFunction: getAppraisalFilters,
        };
      case 'forms':
        return {
          additionalFields: <ContractFormsAdditionalFields values={values} />,
          additionalFilterFunction: getContractFormsFilters,
        };
      case 'product':
        return {
          additionalFields: (
            <InventoryProductsAdditionalFields values={values} />
          ),
          additionalFilterFunction: getProductInventoryFilters,
        };
      case 'inventory-item-report':
        return {
          additionalFields: (
            <InventoryItemReportAdditionalFields values={values} />
          ),
          additionalFilterFunction: getProductInventoryItemFilters,
        };
      case 'taxes':
        return {
          additionalFields: <TaxesAdditionalFields values={values} />,
          additionalFilterFunction: getTaxesFilters,
        };
      case 'marketing-reports':
        return {
          additionalFields: <MarketingReportAdditionalFields values={values} />,
          additionalFilterFunction: getMarketingReportFilters,
        };
      case 'product-events':
        return {
          additionalFields: <MemoAdditionalFields values={values} />,
          additionalFilterFunction: getMemoFilters,
        };
      case 'memo-out-reports':
        return {
          additionalFields: <MemoOutAdditionalFields values={values} />,
          additionalFilterFunction: getMemoOutFilters,
        };
      case 'requests':
        return {
          additionalFields: <PurchaseRequestAdditionalFields values={values} />,
          additionalFilterFunction: getPurchaseRequestFilter,
        };
      case 'estimates':
        return {
          additionalFields: <EstimatesAdditionalFields values={values} />,
          additionalFilterFunction: getEstimateFilter,
        };
      case 'products':
        return {
          additionalFields: <ProductsAdditionalFields values={values} />,
          additionalFilterFunction: getProductsFilters,
        };
      default:
        return {};
    }
  }, [entityName, values]);
};
